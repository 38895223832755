import { Viewer } from './Viewer/Viewer';

function App() {
  return (
    <div>
      <Viewer />
    </div >
  );
}

export default App;
